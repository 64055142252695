import PropTypes from "prop-types";

const Navbar = (props) => {
  return (
    <header>
      <nav className={props.fixed ? "navbar fixed-top navbar-expand" : "navbar navbar-expand"}>
        <div className="container-sm px-0">
          <ul className="navbar-nav w-100 p-0 m-0">
            <div className="d-flex w-100 align-items-center">
              <div className="col px-0">{props.left}</div>
              <div className="col-auto navbar-title text-center">
                <h1 className={`h3 mb-0 ${props.titleClass}`}>{props.title}</h1>
                {props.subtitle && <small className="text-gray2 label-2">{props.subtitle}</small>}
              </div>
              <div className="col d-flex justify-content-end px-0 text-end">{props.right}</div>
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

Navbar.defaultProps = {
  title: "\xa0", // empty space
  fixed: true
};

Navbar.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  // title: PropTypes.string,
};
