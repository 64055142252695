export const EMPTY_SPACE = "\xa0";
export const VISIBLE = ["everyone", "group"];
export const MAX_TEXTAREA_LENGTH = 250;
export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const ISO_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const ISO_TIME_FORMAT_MINUTES = "YYYY-MM-DDTHH:mm";
export const ONLY_TIME_FORMAT = "HH:mm";
export const DATE_WORD_FORMAT = "D ddd";
export const DATE_FORMAT = "DD. MM. YYYY";
export const SHORT_YEAR_FORMAT = "HH:MM DD.MM.YY";
export const COLORS = ["#49BCD6", "#FF5B5B", "#FFC000", "#704FF2"];
export const REDIRECT_URL_REGEX = /\/\S+/;
export const MAX_DESCRIPTION_LENGTH = 1000;
export const ICON_SIZE = 26;
export const REGISTRATION_STEPS = 4;

export const APPROVAL_TIMES = [
  { value: 6, unit: "hours", label: "h" },
  { value: 12, unit: "hours", label: "h" },
  { value: 1, unit: "days", label: "d" },
  { value: 2, unit: "days", label: "d" },
  { value: 3, unit: "days", label: "d" },
];

export const activityLevels = (t) => {
  return [
    {
      value: "beginner",
      name: t("common:users_activity.levels.beginner"),
      number: 1,
    },
    {
      value: "intermediate",
      name: t("common:users_activity.levels.intermediate"),
      number: 2,
    },
    {
      value: "experienced",
      name: t("common:users_activity.levels.experienced"),
      number: 3,
    },
  ];
};

export const repeatTimes = (x) => (f) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

export const userSetupStatusRedirect = (setup_status, path = "/") => {
  if (!setup_status) return;

  if (setup_status === "created") {
    return "/registration/name";
  } else if (setup_status === "finished") {
    return path;
  } else if (setup_status === "name") {
    return "/registration/name";
  } else if (setup_status === "avatar") {
    return "/registration/photo";
  } else if (setup_status === "info") {
    return "/registration/user_info";
  } else if (setup_status === "info_step2") {
    return "/registration/preferences";
  } else if (setup_status === "preferences") {
    return "/registration/preferences";
  } else {
    return "/registration/finish";
  }
};

export const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
};

export const getLocalStorage = (key, initialValue = null) => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
};

export const parseUserActivities = (activities) => {
  return activities.map((activity) => {
    return {
      activity_id: activity.id,
      level: activity.level,
      teacher: activity.teacher,
    };
  });
};

export const generateEventName = (event, user, t) => {
  return `${event.activity?.name} ${t("common:with")} ${
    event.admin_group ? event.admin_group?.name : user?.first_name
  }`;
};

export async function copyTextToClipboard(window, text) {
  if (isReactNativeApp(window)) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "COPY_TEXT_TO_CLIPBOARD",
        text: text,
      })
    );
  }
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const scrollWindowToTop = () => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
};

export const generateRange = ({ from, to, step = 1, withZero = true }) => {
  const result = [];
  for (let i = from; i <= to; i += step) {
    withZero && i < 10 ? result.push(`0${i}`) : result.push(`${i}`);
  }
  return result;
};

export const goBack = (router, window = null, replace = false) => {
  if (router.query.backLink && router.query.backLink !== "undefined") {
    if (replace) {
      router.replace(router.query.backLink);
    } else {
      router.push(router.query.backLink);
    }
  } else {
    if (window && (window.history.state == null || window.history.state?.idx == 0)) {
      router.push("/");
    } else {
      router.back();
    }
  }
};

export const address = (obj) =>
  [obj.leisure, obj.street, obj.city].filter((key) => key !== null && key !== undefined).join(", ");

export const truncate = (string, length = 50) =>
  string && string.length > length ? string.slice(0, length).concat("...") : string;

export const getMaxTextLengthByScreen = (window) => {
  if (window == undefined) return;

  const width = window.screen.width;
  if (width <= 375) {
    return parseInt((window.screen.width / 100) * 3);
  } else if (width > 375 && width < 450) {
    return parseInt((window.screen.width / 100) * 5);
  } else {
    return parseInt((window.screen.width / 100) * 6);
  }
};

export const isIOSApp = (userAgent) => {
  return userAgent.includes("UnalanuiOSApp");
};

export const isAndroidApp = (userAgent) => {
  return userAgent.includes("UnalanuAndroidApp");
};

export const isMobileApp = (userAgent) => {
  return userAgent.includes("UnalanuMobileApp");
};

export const getAfterSignInPath = (sessionStorage) => {
  const signInPath = sessionStorage.getItem("after_sign_in_path");
  return signInPath ? Buffer.from(signInPath, "base64").toString("utf-8") : null;
};

export const getAndClearAfterSignInPath = (sessionStorage) => {
  const signInPath = getAfterSignInPath(sessionStorage);

  if (signInPath && sessionStorage.getItem("after_sign_in_path")) {
    sessionStorage.removeItem("after_sign_in_path");
  }

  return signInPath;
};

export const groupNewResourceLink = (token, resource = "events") =>
  `/${resource}/new?group_token=${token}&backLink=/groups/${token}/events`;

export const getEmojiByStatus = (status) => {
  if (status === "waiting_approval") {
    return "🔒 ";
  } else if (status === "invited_by_creator" || status === "invited") {
    return "⏳ ";
  } else if (status === "declined") {
    return "⛔ ";
  } else if (status === "approved") {
    return "";
  } else if (status === "share_allowed") {
    return "📢 ";
  } else {
    return "";
  }
};

export const isReactNativeApp = (window) => {
  return typeof window !== undefined && typeof window !== "undefined" && window.ReactNativeWebView;
};

export function generateToken(n) {
  var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var token = "";
  for (var i = 0; i < n; i++) {
    token += chars[Math.floor(Math.random() * chars.length)];
  }
  return token;
}

export function appendURLParam(link, param) {
  return link.includes("?") ? `${link}&${param}` : `${link}?${param}`;
}

export const isFalsey = (val) => [null, undefined].includes(val);
export const isTruthy = (val) => !isFalsey(val);

export const fullName = (user) => {
  if (!user) return "";

  if (user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return user.first_name;
};

export const highlightLinksInText = (text) => {
  const regex = /((http|https):\/\/[^\s]+)/g;
  const replacedText = text?.replace(regex, '<a href="$1" target="_blank">$1</a>');
  return replacedText;
};

export const shorten = (text, maxLength, separator = " ") => {
  if (text.length <= maxLength) return text.trim();
  return text.substr(0, text.lastIndexOf(separator, maxLength)).trim();
};

export const usersGroupsStatuses = {
  invited: 1,
  invited_by_creator: 2,
  waiting_approval: 3,
  approved: 4,
  declined: 5,
  share_allowed: 6,
};

export const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

export const directUploadFile = async (file, handleFunction) => {
  const { DirectUpload } = await import("@rails/activestorage");
  const url = `${process.env.NEXT_PUBLIC_API_LOCAL_URL}/rails/active_storage/direct_uploads`;
  const upload = new DirectUpload(file, url);

  upload.create(handleFunction);
};

export const langugageEmojiMapper = (language) => {
  const languageEmojis = {
    en: "🇬🇧",
    cs: "🇨🇿",
    sk: "🇸🇰",
  };

  return languageEmojis[language];
};

export const setLocaleCookie = (document, locale) => {
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
};
