import Image from "next/image";
import useDynamicSVGImport from "../../hooks/use_dynamic_svg";
import PropTypes from 'prop-types';

const ImageIcon = (props) => {
  const { SvgIcon, loading } = useDynamicSVGImport(props.name);
  const { raw, ...passProps } = props;

  return (
    <>
      {props.raw ? (
        <>{!loading && SvgIcon && <SvgIcon {...passProps} />}</>
      ) : (
        <Image
          src={`/images/icons/${props.name}.svg`}
          alt={props.alt || props.name}
          width={props.width}
          height={props.height}
          className={props.className}
          title={props.name}
          layout="raw"
        />
      )}
    </>
  );
};

ImageIcon.propTypes = {
  raw: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

ImageIcon.defaultProps = {
  raw: false,
  width: 32,
  height: 32
}

export default ImageIcon;
